import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import RideChain from "../../contracts/RideChain.json";
import RCToken from "../../contracts/RCToken.json";
import RCPlusHandler from "../../contracts/RCPlusSwapHandler.json";
import TokenPair from "../../contracts/TokenPair.json";
import USDTContract from "../../contracts/USDTContract.json";
import OTCMarket from "../../contracts/OTCMarket.json";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == 56) {
          const RideChainObj = new Web3EthContract(
            RideChain.abi,
            "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4"
          );
          const RCTokenObj = new Web3EthContract(
            RCToken.abi,
            "0x3dE5494f9c61f6dE91b5eB7db0b1CF7c898fF370"
          );
          const RCPlusHandlerObj = new Web3EthContract(
            RCPlusHandler.abi,
            "0x787c716A9801716dC82455C75f8EbfF8C06b299B"
          );
          const TokenPairObj = new Web3EthContract(
            TokenPair.abi,
            "0x732a6cc4295cde54ebb44371d982c34934bbebd4"
          );
          const USDTContractObj = new Web3EthContract(
            USDTContract.abi,
            "0x55d398326f99059fF775485246999027B3197955"
          );
          const OTCMarketObj = new Web3EthContract(
            OTCMarket.abi,
            "0x99aa3a2E637325B8830019579fb94835549552b1"
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              rideChain: RideChainObj,
              rcToken: RCTokenObj,
              rcPlusHandler: RCPlusHandlerObj,
              tokenPair: TokenPairObj,
              usdtContract: USDTContractObj,
              otcMarket: OTCMarketObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
            alert("钱包已被更换，请重新登入D-App。");
            window.location.reload();
          });
          ethereum.on("chainChanged", () => {
            alert("成功转换区块链，请重新链接钱包。");
            window.location.reload();
          });
          // Add listeners end
        } else {
          alert("请转换使用币安（BSC）区块链。");
        }
      } catch (err) {
        alert("请链接区块链钱包使用D-App。");
      }
    } else {
      // dispatch(connectFailed("Please install Metamask to use our application."));
      alert("请链接区块链钱包使用D-App。");
    }
  };
  
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
  };
};

