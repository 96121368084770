import { useState } from 'react';

export const useClaim = () => {
    const [isClaiming, setIsClaiming] = useState(null);
    const [claimSuccess, setClaimSuccess] = useState(null);

    const createRCClaim = async (curUserID, userWallet, claimAmount) => {
        setIsClaiming(true);

        const response = await fetch('https://api.ridechain.io/claim/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                wallet: userWallet,
                withdrawal_amount: claimAmount,
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setTimeout(() => setIsClaiming(false), 3000);
        }
        if (response.ok) {
            setClaimSuccess(true);
            setTimeout(() => setIsClaiming(false), 3000);
        }
    }

    return { isClaiming, setIsClaiming, claimSuccess, setClaimSuccess, createRCClaim };
}