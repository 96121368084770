import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import { setLanguage, t } from '../translation.js';

import ProfileBanner from '../assets/img/profile-page-banner.jpg';

import CustomBtn from '../assets/img/custom-btn.png';

import SmallDataBox from '../assets/img/box-small.png';
import BigDataBox from '../assets/img/box-big.png';

// hooks
import { useWithdrawal } from '../hooks/useWithdrawal.js';

const EarningPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [selectedDate, setSelectedDate] = useState(Moment(new Date()).format('YYYY/MM/DD'));
    const handleDateChange = (e) => {
        const selected = e.target.value;
        setSelectedDate(selected);
    }

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData("User Data:", json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
		}
	};

	const [investData, setInvestData] = useState([]);
	const fetchInvestData = async () => {
		const response = await fetch('https://api.ridechain.io/deposit/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {

			console.log("Invest Data:", json);
			setInvestData(json);

		}
	};

	const [earningData, setEarningData] = useState([]);
	const fetchEarningData = async () => {
		try {
			const response = await fetch(
				`https://api.ridechain.io/user/earning/wallet/${blockchain.account}`
			);
			const json = await response.json();
			if (response.ok) {
				console.log("Earning Data:", json);

					setEarningData(json.earnings);

			} else {
				console.error("Failed to fetch earning data:", json);
				setEarningData([]); // Handle error by setting empty array
			}
		} catch (error) {
			console.error("Error fetching earning data:", error.message);
			setEarningData([]); // Handle fetch errors
		}
	};

	const [filteredEarnings, setFilteredEarnings] = useState([]);

	// Filter earning data based on selectedDate
	useEffect(() => {
		const filtered = earningData.filter((earning) => {
			const earningDate = Moment(earning.date).format('YYYY-MM-DD');
			const formattedSelectedDate = Moment(selectedDate).format('YYYY-MM-DD');
			return earningDate === formattedSelectedDate;
		});
		setFilteredEarnings(filtered);
	}, [selectedDate, earningData]);

	useEffect(() => {
		console.log("Account Page", blockchain.account);
		fetchUserData();
		fetchInvestData();
		fetchEarningData();
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 px-0">
						<img className="w-100" src={ProfileBanner}></img>
					</div>
				</div>
			</div>

			<div className="main-content profile-bg">
				<div className="container pt-5">
					<div className="row">

						<div className="col-12 text-center">
							<h3 className="brand-blue">{t('select-date')}</h3>
						</div>

						<div className="col-12 text-center px-3">
							<input
								type="date"
								value={selectedDate}
								onChange={handleDateChange}
								className="form-control mb-3"
							/>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">{t('earning-page')}</h3>
						</div>

						{filteredEarnings.length > 0 ? (
							filteredEarnings.map((earning, index) => (
								<>
									<div className="col-12 text-center">
										<div className="data-container">
											<img className="w-100" src={BigDataBox}></img>
											<div className="data-text text-left">
												<p className="brand-yellow mb-0"><strong>{t('earning-date')}: <span className="text-white">{Moment(selectedDate).format('YYYY/MM/DD')}</span></strong> </p>
												<p className="brand-yellow mt-2 mb-0"><strong>{t('static-earning')}: <span className="text-white">{parseFloat(earning.miningProfit).toFixed(2)}</span></strong> </p>
												<p className="brand-yellow mt-2 mb-0"><strong>{t('team-earning')}: <span className="text-white">{parseFloat(earning.rankBonus).toFixed(2)}</span></strong> </p>
												{/* <p className="brand-yellow mt-2 mb-0"><strong>{t('rank-bonus')}: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p> */}
											</div>
										</div>
									</div>
									<div className="col-12 mt-5 text-center">
										<div className="data-container">
											<img className="w-100" src={BigDataBox}></img>
											<div className="data-text text-left">
												<p className="brand-yellow mb-0"><strong>RC: <span className="text-white">{parseFloat(earning.totalRC).toFixed(2)}</span></strong> </p>
												<p className="brand-yellow mt-2 mb-0"><strong>RCT: <span className="text-white">{parseFloat(earning.totalRCT).toFixed(2)}</span></strong> </p>
												<p className="brand-yellow mt-2 mb-0"><strong>SRC: <span className="text-white">{parseFloat(earning.totalSRC).toFixed(2)}</span></strong> </p>
											</div>
										</div>
									</div>
								</>
							))
						):(
							<>
								<div className="col-12 text-center">
									<div className="data-container">
										<img className="w-100" src={BigDataBox}></img>
										<div className="data-text text-left">
											<p className="brand-yellow mb-0"><strong>{t('earning-date')}: <span className="text-white">{Moment(selectedDate).format('YYYY/MM/DD')}</span></strong> </p>
											<p className="brand-yellow mt-2 mb-0"><strong>{t('static-earning')}: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p>
											<p className="brand-yellow mt-2 mb-0"><strong>{t('team-earning')}: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p>
											{/* <p className="brand-yellow mt-2 mb-0"><strong>{t('rank-bonus')}: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p> */}
										</div>
									</div>
								</div>
								<div className="col-12 mt-5 text-center">
									<div className="data-container">
										<img className="w-100" src={BigDataBox}></img>
										<div className="data-text text-left">
											<p className="brand-yellow mb-0"><strong>RC: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p>
											<p className="brand-yellow mt-2 mb-0"><strong>RCT: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p>
											<p className="brand-yellow mt-2 mb-0"><strong>SRC: <span className="text-white">{parseFloat(0).toFixed(2)}</span></strong> </p>
										</div>
									</div>
								</div>
							</>
						)}

						<div className="col-12 mt-2 text-center">
							<NavLink to="/profile">
								<button className="btn custom-btn mt-3" onClick={(e) => {
									window.scrollTo(0, 0);
								}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('back-btn')}</strong></h5>
								</button>
							</NavLink>
						</div>

					</div>
				</div>
			</div>
		</>
	);

};

export default EarningPage;